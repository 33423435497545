<template>
<div class="sm:block hidden">
  <el-dialog v-model="renameSessionDialogVisible" title="Renommer la série" width="40%" center :before-close="closeModal" destroy-on-close>
    <el-input placeholder="Donnez un nouveau nom à la série" v-model="name"/>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="renameSession"  class="py-2 px-5 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Valider
        </button>
      </span>
    </template>
  </el-dialog>
</div>
<div class="sm:hidden block" >
  <el-dialog v-model="renameSessionDialogVisible" title="Renommer la série" width="85%" center :before-close="closeModal" destroy-on-close>
    <el-input placeholder="Donnez un nouveau nom à la série" v-model="name"/>
    <template #footer>
      <span class="dialog-footer">
        <button @click="closeModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Annuler
        </button>
        <button @click="renameSession"  class="py-2 px-5 mx-1 sm:my-0 my-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Valider
        </button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>
import axiosIns from '../../../plugins/axios';
export default {
  data () {
    return {
      name: null
    }
  },

  mounted () {
    this.name = this.selectedSession.name
  },
  
  props:{
    renameSessionDialogVisible : Boolean,
    closeRenameSessionModal: Function,
    selectedSession: Object
  },

  methods: {
    closeModal(){
      this.closeRenameSessionModal()
    },

    renameSession(){
      axiosIns.put("/session/"+this.selectedSession.id,{
          name : this.name
        })
        .then(() => {
          // this.isLoading = false;
          this.$emit("closeRenameModalWihRename",this.name);
        })
        .catch(() => {
          // this.isLoading = false;
        });
    }
  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
  },

}
</script>

<style>

</style>